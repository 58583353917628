import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../button/Button';
import { useAppDispatch } from '../../app/hooks';
import { loginUser, clearError } from '../../features/authSlice';
import Spinner from '../spinner/Spinner';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';

interface ModalProps {
  isLoading: boolean;
  children: React.ReactNode;
}
const Modal: React.FC<ModalProps> = ({ children, isLoading }) => {
  if (!isLoading) return null;

  return (
    <div className='fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50'>
      <div
        className={`bg-[#fff0] p-8 rounded-lg relative w-[full] md:w-[27rem] px-4 md:px-8 py-12 text-center m-4 flex justify-center`}
      >
        <p className='text-base text-gray-700'>{children}</p>
      </div>
    </div>
  );
};

interface IFormInput {
  email: string;
  password: string;
  remember: boolean;
}

const SigninForm: React.FC = () => {
  const [formError, setFormError] = useState<string | null>(null);
  const isLoading = useSelector((state: RootState) => state.auth.loading);
  const errorMessage = useSelector((state: RootState) => state.auth.error);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>();
  const dispatch = useAppDispatch();

  const onSubmit = (data: IFormInput) => {
    setFormError(null);

    if (!data.email || !data.password) {
      setFormError('Email and password are required');
      return;
    }

    dispatch(loginUser(data.email, data.password, navigate));
  };

  useEffect(() => {
    if (errorMessage) {
      setFormError(errorMessage);
    }

    return () => {
      dispatch(clearError());
    };
  }, [errorMessage, dispatch]);

  return (
    <section>
      <div className='flex flex-col h-[74.2vh] items-center justify-center px-6 py-8 mx-auto lg:py-0 bg-primaryBg-50'>
        <div className='w-full rounded-lg dark:border md:mt-0 sm:max-w-md xl:p-0'>
          <div className='p-6 space-y-4 md:space-y-6 sm:p-8'>
            <h1 className='text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl'>
              Log In to your account
            </h1>
            <form
              className='space-y-4 md:space-y-6'
              onSubmit={handleSubmit(onSubmit)}
            >
              <div>
                <label
                  htmlFor='email'
                  className='block mb-2 text-sm font-medium text-gray-900'
                >
                  Your email
                </label>
                <input
                  type='email'
                  id='email'
                  {...register('email', { required: 'Email is required' })}
                  className={`bg-gray-50 border border-gray-300 text-gray-900 rounded-[0.25rem] focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                    errors.email && 'border-red-500'
                  }`}
                  placeholder='name@company.com'
                />
                {errors.email && (
                  <p className='text-red-500 text-sm'>{errors.email.message}</p>
                )}
              </div>
              <div>
                <label
                  htmlFor='password'
                  className='block mb-2 text-sm font-medium text-gray-900'
                >
                  Password
                </label>
                <input
                  type='password'
                  id='password'
                  {...register('password', {
                    required: 'Password is required',
                  })}
                  placeholder='••••••••'
                  className={`bg-gray-50 border border-gray-300 text-gray-900 rounded-[0.25rem] focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                    errors.password && 'border-red-500'
                  }`}
                />
                {errors.password && (
                  <p className='text-red-500 text-sm'>
                    {errors.password.message}
                  </p>
                )}
              </div>
              {formError && (
                <div className='text-red-500 text-sm mt-2'>{formError}</div>
              )}
              <Button
                type='submit'
                primary
                className='w-full md:w-auto text-sm px-5'
              >
                Sign in
              </Button>
              <p className='text-sm font-light text-gray-500 dark:text-gray-400'>
                Don’t have an account yet?{' '}
                <Link
                  to='/signup'
                  className='font-medium text-primary hover:underline'
                >
                  Get Started
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
      <Modal isLoading={isLoading}>{isLoading && <Spinner />}</Modal>
    </section>
  );
};

export default SigninForm;
