import { Action, configureStore, combineReducers } from '@reduxjs/toolkit';
import { authReducer, logout } from '../features/authSlice';
import { searchReducer } from '../features/searchSlice';
import { PartnerReducer } from '../features/partnerSlice';
import { thunk, ThunkAction } from 'redux-thunk';

// Combine all reducers
const appReducer = combineReducers({
  auth: authReducer,
  search: searchReducer,
  partner: PartnerReducer,
});

// Root reducer that resets state on logout
const rootReducer = (state: any, action: any) => {
  // Check if the action is logout, reset the store to undefined
  if (action.type === logout.type) {
    // Set the state to undefined to reset all slices
    state = undefined;
  }
  return appReducer(state, action);
};

// Configure the store
export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(thunk),
});

// Types for state and dispatch
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
