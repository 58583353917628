import React, { useState } from 'react';
import { images } from '../../constants/images';
import envConfig from '../../utils/config';
import Spinner from '../../components/spinner/Spinner';

// Define types for Modal props
interface ModalProps {
  show: boolean;
  onClose: () => void;
  title: string;
  isLoading: boolean;
  children: React.ReactNode;
}

// Modal Component with TypeScript types
const Modal: React.FC<ModalProps> = ({
  show,
  onClose,
  title,
  children,
  isLoading,
}) => {
  if (!show) return null;

  return (
    <div className='fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50'>
      <div
        className={`${isLoading ? '#fff0' : 'bg-white shadow-lg'} p-8 rounded-lg  relative w-[full] md:w-[27rem] px-4 md:px-8 py-12 text-center m-4`}
      >
        <button
          className={`${isLoading ? 'hidden' : 'block'} absolute top-2 right-2 text-gray-600 hover:text-gray-800 text-3xl`}
          onClick={onClose}
        >
          &times;
        </button>
        <h3 className='text-xl font-semibold mb-4 text-[#247f00] text-center'>
          {' '}
          {/* Green heading, centered, with padding */}
          {title}
        </h3>
        <p className='text-base text-gray-700'>{children}</p>
      </div>
    </div>
  );
};

const GetInTouchForm: React.FC = () => {
  const [formData, setFormData] = useState({
    subject: 'Support Email',
    body: '',
    userId: 38,
    email: '',
  });

  const [isModalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', body: '' });
  const [isLoading, setIsLoading] = useState(false);

  // Handle input change
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setModalVisible(true);
      setIsLoading(true);
      const response = await fetch(
        `${envConfig.REACT_APP_API_BASE_URL}/sys/sys/s`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': `${envConfig.REACT_APP_API_X_API_KEY}`,
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        // Show Success Modal
        setModalContent({
          title: 'Form Submitted Successfully',
          body: 'Thank you for getting in touch! Your response has been recorded, and our team will contact you soon.',
        });

        // Reset the form
        (document.getElementById('contact-us-form') as HTMLFormElement).reset();
        setFormData({ ...formData, body: '', email: '' }); // Reset formData state as well
      } else {
        // Handle error response
        const errorData = await response.json();
        console.error('Error submitting form:', errorData);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='bg-[#F8F9FF]' id='contact-form'>
      <div className='container-fluid md:container mt-6 md:mt-16 mx-auto md:px-4'>
        <h2 className='text-2xl md:text-[2.375rem] md:leading-10 md:m-4 md:mt-8 mb-4 font-bold text-black'>
          Let's get in touch!
        </h2>
        <p className='text-base md:px-4'>
          Got questions about the Savers Club? Our team is here to help. Contact
          us for quick and friendly support.
        </p>
        <div className='mx-auto my-8 md:p-6 '>
          <form
            className='grid grid-cols-1 md:grid-cols-2 gap-6'
            onSubmit={handleSubmit}
            id='contact-us-form'
          >
            {/* Left Column */}
            <div className='space-y-6'>
              {/* Email Field */}
              <div className='relative mb-6'>
                <div className='absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none'>
                  <img src={images.envelope} alt='' />
                </div>
                <input
                  type='email'
                  id='email'
                  name='email'
                  className='input-field'
                  placeholder='Email'
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>

              {/* Name of Entity Field */}
              <div className='relative mb-6'>
                <div className='absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none'>
                  <img src={images.entity} alt='' />
                </div>
                <input
                  type='text'
                  id='entity-name'
                  name='entityName'
                  className='input-field'
                  placeholder='Name of your Entity'
                  onChange={handleChange}
                  required
                />
              </div>

              {/* Name of Contact Field */}
              <div className='relative mb-6'>
                <div className='absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none'>
                  <img src={images.contact} alt='' />
                </div>
                <input
                  type='text'
                  id='contact-name'
                  name='contactName'
                  className='input-field'
                  placeholder='Name of Contact'
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            {/* Right Column */}
            <div className='space-y-6'>
              {/* Message Field */}
              <div className='relative mb-6'>
                <div className='absolute inset-y-0 start-0 flex  ps-3.5 pointer-events-none pt-[0.85rem]'>
                  <img
                    src={images.message}
                    className='w-[15px] h-[15px] mt-[3px]'
                    alt=''
                  />
                </div>
                <textarea
                  id='message'
                  name='body'
                  className='input-field h-[8rem] resize-none'
                  placeholder='Message'
                  value={formData.body}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>

              {/* Submit Button */}
              <div>
                <button
                  type='submit'
                  className='w-full px-4 py-4 text-white bg-primary rounded-lg hover:primary transition-colors duration-200 font-bold'
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* Modal Component */}
      <Modal
        show={isModalVisible}
        isLoading={isLoading}
        onClose={() => setModalVisible(false)}
        title={modalContent.title}
      >
        {isLoading && <Spinner />}
        {modalContent.body}
      </Modal>
    </div>
  );
};

export default GetInTouchForm;
