import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { Navigate } from 'react-router-dom';

interface Props {
  children: React.ReactNode;
}

const PublicRoute: React.FC<Props> = ({ children }) => {
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );
  if (isAuthenticated) {
    return <Navigate to='/dashboard' />;
  }
  return <>{children}</>;
};

const ProtectedRoute: React.FC<Props> = ({ children }) => {
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );
  if (!isAuthenticated) {
    return <Navigate to='/' />;
  }
  return <>{children}</>;
};

export { ProtectedRoute, PublicRoute };
