import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';

interface Props {
  toggleDrawer: () => void;
  isDrawerOpen: boolean;
}

const Navbar = ({ isDrawerOpen, toggleDrawer }: Props) => {
  const userDetails = localStorage.getItem('userDetails');
  let user;
  const userStatus = useSelector((state: RootState) => state.auth.status);
  if (userDetails) {
    user = JSON.parse(userDetails);
  }
  const [statusText, setStatusText] = useState('');
  const [statusColor, setStatusColor] = useState('');
  useEffect(() => {
    // Map status to corresponding text and color classes
    const statusMapping = {
      active: { text: 'Active', color: 'text-green-400' },
      inactive: { text: 'Update Brand Info', color: 'text-red-400' },
      inreview: { text: 'In Review', color: 'text-yellow-400' },
      suspended: { text: 'Suspended', color: 'text-red-400' },
      onhold: { text: 'On Hold', color: 'text-orange-400' },
      null: { text: '', color: '#000000' },
    };

    // Get the appropriate status text and color based on userStatus
    const { text, color } = statusMapping[userStatus] || {
      text: 'Unknown',
      color: 'text-gray-400',
    };

    setStatusText(text);
    setStatusColor(color);
  }, [userStatus]);

  // const  user  = useAppSelector((state: RootState) => state.auth.userDetails);
  return (
    <>
      <nav
        className={`grid md:grid-cols-2 place-content-center  h-16 w-full bg-primaryBg-50  ${isDrawerOpen ? 'lg:pr-7 pr-3 md:pr-5' : 'lg:px-7 px-3 md:px-5'} `}
      >
        {/*menu-icon div*/}
        <div className='flex items-center justify-start'>
          <button
            onClick={toggleDrawer}
            className='text-gray-900 focus:outline-none pr-7'
          >
            <svg
              className='w-6 h-6'
              fill='#333399'
              stroke='#333399'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M4 6h16M4 12h16M4 18h16'
              ></path>
            </svg>
          </button>

          <div className='hidden md:block'>
            {/* <SearchInput /> */}
            <div className='flex flex-row md:gap-[4rem] xmd:gap-[7rem]'>
              <h4 className='md:text-[0.75rem] xmd:text-[1.25rem]'>
                {user?.partner?.name}
              </h4>
              <h4 className='md:text-[0.75rem] xmd:text-[1.25rem]'>
                Status:{' '}
                <span className={`${statusColor} mt-[1px] font-semibold`}>
                  {statusText}
                </span>
              </h4>
            </div>
          </div>
          <div className='block md:hidden'>
            {/* <SearchInput /> */}
            <div className='flex flex-row gap-10 md:gap-[7rem]'>
              <h4 className='md:text-[0.75rem] xmd:text-[1.25rem]'>
                {user?.partner?.name}
              </h4>
              <h4 className='md:text-[0.75rem] xmd:text-[1.25rem]'>
                Status:{' '}
                <span className={`${statusColor} mt-[1px] font-semibold`}>
                  {statusText}
                </span>
              </h4>
            </div>
          </div>
        </div>
        <div className='hidden md:flex space-x-1 justify-end items-center'>
          <div className='hidden md:block whitespace-nowrap text-primary'>
            <p className='font-semibold text-[1.25rem] pt-2 '>
              {user?.firstName} {user?.lastName}
            </p>
            {/* <p className=' text-xs font-normal'>Software Engineer</p> */}
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
